<template>
  <justified-layout
    class="multipart-view__grid"
    style="width: 100%;"
    ref='ig'
    v-if="show"
    :options="options"
    :layoutOptions="layoutOptions"
    @append="onRequestAppend"
  >
    <v-card
      v-for="item in multipartViewItems"
      :key="`item-${item[0]}`"
      style="border-radius: 18px;"
      class="multipart-view__item"
      :width="$store.getters.columnWidth"
    >
      <component
        :is="`${item[1].type}Type`"
        :item-id="item[0]"
        :item="item[1]"
        :loaded="resourceLoaded"
        :height="height"
        class="d-flex flex-column flex-nowrap flex-grow-1"
        :class="{
          'no-radius': item[1].info.title || item[1].info.description
        }"
        :broken-ids="[]"
      />
    </v-card> 
  </justified-layout>
</template>
<script>
const imageType = () => import('@/components/ItemTypes/image')
const noteType = () => import('@/components/ItemTypes/note')

export default {
  props: {
    items: {
      type: Array,
      required: true
    }
  },
  name: 'ItemDialogMultipartView',
  components: {
    imageType,
    noteType
  },
  data: () => ({
    show: false,
    multipartViewItems: [],
    resourceLoaded: true,
    height: 300,
    options: {
      transitionDuration: 0.2,
      useFit: true,
      useRecycle: false,
      horizontal: false,
      isOverflowScroll: false
    },
    layoutOptions: {
      margin: 16
    }
  }),
  mounted() {
    setTimeout(() => {
      this.show = true
    },300)
  },
  methods: {
    getItems(nextGroupKey) {
      const nextItems = this.items.slice(this.multipartViewItems.length).map((item, i) => {
        item[2] = {
          groupKey: nextGroupKey,
          key: i
        }
        return item
      })

      return nextItems
    },
    onRequestAppend(e) {
      const nextGroupKey = (typeof e.groupKey === "undefined" ? 0 : +e.groupKey || 0) + 1
      const nextItems = this.getItems(nextGroupKey)
      nextItems.forEach(item => {
        this.multipartViewItems.push(item)
      })
    }
  }
}
</script>